import { IonContent, IonPage, IonText } from '@ionic/react';
import Toolbar from '../components/ToolBar';
import React  from 'react';

const PrivacyDoc: React.FC = () => {
    return (
      <IonPage>
        <Toolbar />
        <IonContent>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h1><strong>Health Recovery Solutions Canada, Inc. Privacy Policy</strong></h1>
                <h4><strong> Effective March 31, 2023 </strong></h4>
                <h4>Commitment to Your Privacy</h4>
            </IonText>

            <p style={{ display: 'flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                Health Recovery Solutions Canada, Inc. on its own behalf and on behalf of its affiliates and/or wholly owned subsidiaries (collectively referred to as “HRS”, “we”, “us”, or “our”), 
                makes certain health related information available to you and facilitates your access to various services including, but not limited to, remote patient monitoring, telehealth, chronic condition management, 
                and PatientConnect (“HRS Services” or “Services”).  
                </IonText>
            </p>


            <p style={{ display: 'flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                While facilitating your access to the Services, HRS collects, uses and discloses your personal and health information.  HRS values our members, including you (“you”), and we respect your privacy.  We are required by law to maintain the privacy of the personal and health information we collect about you and to provide you with notice of our legal duties and privacy practices.
                </IonText>
            </p>

            <p style={{ display: 'flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                This Notice of Privacy Practices (“Notice”) describes the kinds of personal and medical information we collect, use and disclose about you, why we collect it, how we use it, how we protect it, and under what circumstances we share it with third parties.  This Notice also describes how you may access the information HRS has about you and the rights you have with respect to your personal information. Please review this Notice carefully.  
                </IonText>
            </p>


            <p style={{ display: 'flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                HRS must abide by the terms of this Notice while it is in effect. This Notice will remain in effect until HRS replaces it. HRS reserves the right to change the terms of this Notice at any time, as long as the changes are in compliance with applicable law. If HRS changes the terms of this Notice, you will be provided with an updated Notice and asked to agree to it before continuing to use HRS Services.   If the provisions of this Notice conflict with any other privacy policies of HRS, then the provisions of this Notice shall prevail. 
                </IonText>
            </p>


            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>Personal Information Collected and Maintained</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                For the purposes of this Notice, personal information (“Personal Information”) is any information that may be used to identify you such as name, address, phone number, email, and includes your personal health information (“PHI”). 
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                When you use our Services, whether through our secure websites or mobile applications (“Platforms”), telephone, or any other manner, we collect the following types of Personal Information about you: 
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text' style={{ textDecoration: 'underline' }}>
                Personal Information HRS Automatically Collects about You 
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                When you register and log in to the Platforms, HRS automatically collects certain information concerning the type of devices that you are using to access our Platforms.
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text' style={{ textDecoration: 'underline' }}>
                Personal Information You Provide to HRS
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                During the course of you using the Services, you will provide and HRS will collect, your PHI.  PHI includes your past, present or future physical or mental health or condition, the provision of healthcare you have or will receive, and your past, present, or future payment for the provision of healthcare. The PHI HRS will collect during your use of the Services includes but is not limited to:
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                    <ul>
                        <li>Name</li>
                        <li>Address</li>
                        <li>Birth date</li>
                        <li>Email address</li>
                        <li>Telephone number</li>
                        <li>Medical Records and previous medical history</li>
                        <li>Diagnosis and symptoms</li>
                        <li>Lab tests and reports</li>
                        <li>Treatment plans and notes</li>
                        <li>Prescribed medications</li>
                    </ul>
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                Telephone calls, emails, and other communications between you and HRS and/or HRS’s service providers, may be recorded and logged.  As such, we will collect and maintain all information discussed during such communications including your identity, the date and time of the communication, and the contents of the communications.
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text' style={{ textDecoration: 'underline' }}>
                Personal Information HRS Collects About You From Other Sources
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                In connection with the Services, we may collect medical records from your past or current health care providers via their electronic medical records.  
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>Uses and Disclosures of Personal Information</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                As the provider of the HRS Services, HRS may use your PHI when supporting its clients, who are healthcare providers, through the provision of the following services:
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                    <ul>
                        <li>Enabling health care providers to use telemedicine components of the system to facilitate care provided to the patient;</li>
                        <li>Safeguarding the PHI stored, processed and managed in the system;</li>
                        <li>Limiting use of PHI to only the purposes identified by HRS’s clients in agreements and ensuring that staff only access the least amount of PHI required to meet the identified purpose;</li>
                        <li>Logging and reviewing events and activities such as access to PHI; and</li>
                        <li>Maintaining an enterprise-wide privacy program to support HRS’s compliance with the requirements of PHIPA and its regulations as well as our agreements with health care providers. We follow recognized standards in privacy, security and information management to safeguard your PHI more broadly. </li>
                    </ul>
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                Outside of the items above, HRS may use and disclose your PHI in connection with its management and operations, such as:
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                    <ul>
                        <li>Providing customer service;</li>
                        <li>Training, conducting quality review assessments and performing improvement activities;</li>
                        <li>Care coordination and disease management;</li>
                        <li>For auditing, medical review, legal services, accounting, insurance, business planning, business management and other general administrative activities;</li>
                        <li>To develop new products or features, and enhance our Services including the Platforms;</li>
                        <li>Ensure the proper functioning of our websites and Platforms;</li>
                        <li>For information technology services and cloud services.</li>
                    </ul>
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>Disclosures to Third Party Service Providers</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                For any of the above purposes, HRS may engage third parties to perform the function on its behalf. If any such third party must have access to your PHI in order to perform the Services, HRS will require that third party to enter into an agreement that binds the third party to the use and disclosure restrictions outlined in this Notice.  Additionally, we will make sure the third party retains the Personal Information only for as long as is minimally necessary or as required by law. 
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>Your Rights with Respect to your Personal Information</h4>
            </IonText>


            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text' style={{ textDecoration: 'underline' }}>
                    Right to Access Personal Information
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                    You have the right to obtain a copy of the PHI that HRS maintains about you.  To access a copy of your PHI, you must submit your request in writing to HRS. HRS may impose a fee for the costs of copying, mailing, labor and supplies associated with your request. HRS may deny your request to inspect and/or copy your PHI in certain limited circumstances. If that occurs, HRS will inform you of the reason for the denial, and you may request a review of the denial.
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text' style={{ textDecoration: 'underline' }}>
                    Right to Correct Personal Information
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                You have a right to request that HRS amend your PHI if you believe it is incorrect or incomplete. You must submit your request in writing to HRS and provide a reason to support the requested amendment. HRS may, under certain circumstances, deny your request by sending you a written notice of denial. If HRS denies your request, will inform you of the reason for the denial, and you may request a review of the denial.
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text' style={{ textDecoration: 'underline' }}>
                    Right to Withdraw Consent
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                You may, at any time, withdraw your consent for us to collect, use, and disclose your PHI as outlined in this Notice.  However, withdrawal of consent is subject to legal or contractual restrictions and reasonable notice. As such, we may continue to collect, use, and disclose your PHI as may be required to provide you with access to our Services, and to the extent required or permitted by law. If you refuse to provide us with PHI, or later contact us to withdraw your consent for us to use and disclose the PHI we have already collected, you may not be able to use our Services including our Platforms.  At your request, we will delete your PHI unless we are required to retain it to meet our legal or regulatory obligations including provincial retention guidelines for health care providers.
                </IonText>
            </p>


            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text' style={{ textDecoration: 'underline' }}>
                    Right to ask questions or file a complaint
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                If you have any questions or comments or wish to make a complaint about this Notice or HRS’s privacy practices, you have the right to contact us and speak to someone about your concern.  
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                If you wish to exercise any of these rights, ask questions about our privacy practices, make a complaint, or report a privacy or security violation, please contact us at:
                </IonText>
            </p>
            <p style={{ display: 'inline-flex', justifyContent: 'center'}}>
                <IonText class='legal-doc-text'>
                    By Mail: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Privacy Officer <br></br>
                    &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Health Recovery Solutions, Inc. <br></br>
                    &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;50 Harrison Street, Suite 310 <br></br>
                    &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hoboken, NJ 07030
                </IonText>
            </p>
            <p style={{ display: 'inline-flex', justifyContent: 'center'}}>
                <IonText class='legal-doc-text'>
                    By Email: &nbsp;&nbsp;&nbsp;&nbsp; privacy@healthrecoverysolutions.com 
                </IonText>
            </p>
            <p style={{ display: 'inline-flex', justifyContent: 'center'}}>
                <IonText class='legal-doc-text'>
                    By Phone: &nbsp;&nbsp;&nbsp; 347-699-6477
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                If you contact us to exercise any of the rights above, we will respond to your request within a reasonable time and at no cost to you.  
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>Security of Your Personal Information</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                We aim to provide you with a safe experience. We have in place certain physical, electronic, technological, and organizational safeguards that are designed to protect the security and privacy of your Personal Information against loss, theft, and unauthorized access, disclosure, use or modification. Please note, however, that while we try to create a secure and reliable Platform for members, the confidentiality of any communication or material transmitted to or from the Platforms or via e-mail cannot be guaranteed. 
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                We limit access to your personal information within the Company to individuals on a need-to-know basis.  HRS will retain your Personal Information only for a time and to the extent necessary for the purposes for which it was collected as described in this Notice and for reasonable backup, archival, audit, legal or other similar purposes. 
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>Cross Border Transfer of Personal Information</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                Personal Information may be transferred, processed and retained on servers in countries outside of Canada, including the United States.  
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                    <ul>
                        <li>If you use the remote patient monitoring services while you are located in Canada, your PHI (including treatment plans, diagnostic information, and medical history) will be stored on servers in Canada or the United States.</li>
                        <li>If you use the remote patient monitoring services while you are located in the United States, all your PHI (including treatment plans, diagnostic information, and medical history) will be stored on servers in the United States and may be accessed by individuals in the United States and Canada.</li>
                    </ul>
                </IonText>
            </p>


        </IonContent>
      </IonPage>
    );
  };
  
  export default PrivacyDoc;