import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import OrderCount from './pages/OrderCount';
import SingleOrderOptions from './pages/SingleOrderOptions';
import StandardOrderSingle from './pages/StandardOrder';
import StandardReturn from './pages/StandardReturn';
import StandardRMA from './pages/StandardRMA';
import ThankYou from './pages/ThankYou';
import React  from 'react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import PrivacyDoc from './pages/PrivacyDoc';
import TermsOfUse from './pages/TermsOfUseDoc';


setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/order">
          <OrderCount />
        </Route>
        <Route exact path="/order/single">
          <SingleOrderOptions />
        </Route>
        <Route exact path="/order/single/standard">
          <StandardOrderSingle/>
        </Route>
        <Route exact path="/order/single/return">
          <StandardReturn/>
        </Route>
        <Route exact path="/order/single/rma">
          <StandardRMA/>
        </Route>
        <Route exact path="/thanks">
          <ThankYou/>
        </Route>
        <Route exact path="/legal/privacy">
          <PrivacyDoc/>
        </Route>
        <Route exact path="/legal/terms">
          <TermsOfUse/>
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
