/* eslint-disable */
import { 
    IonButton, 
    IonCard, 
    IonCardContent, 
    IonCardTitle, 
    IonContent, 
    IonInput, 
    IonItem,
    IonTitle, 
    IonLabel, 
    IonNote, 
    IonPage,
    IonSelect,
    IonSelectOption,
    IonText,
    IonCheckbox,  } from '@ionic/react';
import Toolbar from '../components/ToolBar';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory } from 'react-router-dom'
import { validStates, validEnvs, validDomains} from '../common_data';
import React, { useEffect, useState } from 'react';

const test_zap_url = 'https://hooks.zapier.com/hooks/catch/2277921/3hpjg5i/'
const prod_zap_url = 'https://hooks.zapier.com/hooks/catch/2277921/3hiopgs/'

function getCurrentDateTime() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

async function createRMAOrder(data: any, orderSubmitted: string) {

    if (data['virtualInstall'] == true) {
        data['virtualInstall'] = 'YES'
    }

    if (data['virtualInstall'] == false) {
        data['virtualInstall'] = 'No'
    }

    const payload = {
        organization: data['ordererOrganization'],
        clinicianName: data['ordererName'],
        clinicianEmail: data['ordererEmail'],
        clinicianPhoneNumber: data['ordererPhoneNumber'].replace(/\D/g, ''),
        inventorySubset: data['inventorySubset'],
        date: orderSubmitted,
        kitContents: data['replacementKit'],
        warehouseComments: data['warehouseComments'],
        patientName: data['patientName'],
        phoneNumber: data['patientPhoneNumber'].replace(/\D/g, ''),
        address1: data['patientAddress1'],
        address2: data['patientAddress2'],
        city: data['patientCity'],
        state: data['patientState'],
        zip: data['patientPostalCode'],
        patientId: data['patientId'],
        serviceType: data['shippingSpeedSelect'],
        hrsKitNumber: 'N/A',
        supportTicketNumber: 'N/A',
        orderType: 'RMA Order',
        itemWithIssue: 'N/A',
        issueType: 'N/A',
        virtualInstall: data['virtualInstall'], // ?
        returnsCoordination: 'N/A',
        resubmission: 'N/A',
        orderNumberResubmission: 'N/A',
        orderNumberWithIssue: 'N/A',
        tier: 'Essential',
    }

    const res = await fetch(prod_zap_url, {
        method: "POST",
        body: JSON.stringify(payload)
    });

    if (!res.ok) {
        const message = `An error has occured: ${res.status}`;
        throw new Error(message);
    }

    return await res.json();
}


async function createRMAReturn(data: any, orderSubmitted: string) {

    const payload =  {
        organization: data['ordererOrganization'],
        clinicianName: data['ordererName'],
        clinicianEmail: data['ordererEmail'],
        clinicianPhoneNumber: data['ordererPhoneNumber'].replace(/\D/g, ''),
        inventorySubset: data['inventorySubset'],
        date: orderSubmitted,
        kitContents: 'N/A',
        warehouseComments: data['warehouseComments'],
        patientName: data['pickupPatientName'],
        phoneNumber: data['pickupPhoneNumber'].replace(/\D/g, ''),
        address1: data['pickupAddress1'],
        address2: data['pickupAddress2'],
        city: data['pickupCity'],
        state: data['pickupState'],
        zip: data['pickupPostalCode'],
        patientId: data['patientId'],
        serviceType: 'Ground',
        hrsKitNumber: data['inventoryNumber'],  //reference 1
        supportTicketNumber: data['supportTicketNumber'],
        orderType: 'RMA Return',
        itemWithIssue: data['peripheralWithIssue'],
        issueType: data['issueType'],
        virtualInstall: 'N/A',
        returnsCoordination: 'N/A',
        resubmission: 'N/A',
        orderNumberResubmission: 'N/A',
        orderNumberWithIssue: data['orderNumberWithIssue'],
        tier: 'Essential',
    }


    const res = await fetch(prod_zap_url, {
        method: "POST",
        body: JSON.stringify(payload)
    });

    if (!res.ok) {
        const message = `An error has occured: ${res.status}`;
        throw new Error(message);
    }

    return await res.json();
}

const StandardRMA: React.FC = () => {
    const history = useHistory();
    const [isSameAddress, setIsSameAddress] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isReturnAllowed, setIsReturnAllowed] = useState(true);
    const [doesNeedReplacement, setNeedReplacement] = useState(true);

    const handleIsSameAddressChange = (e: any) => {
        setValue('sameAddress', e.detail.checked);
        setIsSameAddress(e.detail.checked);
    };

    const handleNeedReplacement = (e: any) => {
        setValue('needReplacement', e.detail.checked);
        setNeedReplacement(e.detail.checked);
    };

    const handleIsReturnAllowed = (e: any) => {
        setValue('returnAllowed', e.detail.checked);
        setIsReturnAllowed(e.detail.checked);
    }

    

    const {
        handleSubmit,
        control,
        setValue,
        register,
        getValues,
        formState: { isValid, errors }
    } = useForm({
        defaultValues: {
          ordererName: '',
          ordererPhoneNumber: '',
          ordererEmail: '',
          ordererOrganization: '',
          inventorySubset: '',
          peripheralWithIssue: '', //Device for RMA
          inventoryNumber: '',
          supportTicketNumber: '',
          shippingSpeedSelect: 'Ground',
          issueType: '',
          patientName: '',
          patientPhoneNumber: '',
          patientAddress1: '',
          patientAddress2: '',
          patientCity: '',
          patientState: '',
          patientPostalCode: '',
          patientId: '',
          warehouseComments: '',
          orderNumberWithIssue: '',
          replacementKit: '',
          virtualInstall: false,
          sameAddress: true,
          pickupPatientName: '',
          pickupPhoneNumber: '',
          pickupAddress1: '',
          pickupAddress2: '',
          pickupCity: '',
          pickupState: '',
          pickupPostalCode: '',
          needReplacement: true,
          returnAllowed: true
        }
    });

    useEffect(() => {
        setIsFormValid(isValid);
    }, [isValid]);
    
      /**
       *
       * @param data
       */
    const onSubmit = async (data: any) => {
        var orderSubmitted = getCurrentDateTime();

        if (isSameAddress) {
            data['pickupPatientName'] = data['patientName'];
            data['pickupAddress1'] = data['patientAddress1'];
            data['pickupAddress2'] = data['patientAddress2'];
            data['pickupCity'] = data['patientCity'];
            data['pickupState'] = data['patientState'];
            data['pickupPostalCode'] = data['patientPostalCode'];
            data['pickupPhoneNumber'] = data['patientPhoneNumber'];
        }

        if (!doesNeedReplacement){
            createRMAReturn(data, orderSubmitted).then(res => {
                history.push('/thanks')
            }).catch(error => {
                console.log(error.message)
                alert(`${error.message}: please contact support@healthrecoverysolutions.com`)
            })

        }else {
            createRMAOrder(data, orderSubmitted).then(res => {

                createRMAReturn(data, orderSubmitted).then(res => {
                    history.push('/thanks')
                }).catch(error => {
                    console.log(error.message)
                    alert(`${error.message}: please contact support@healthrecoverysolutions.com`)
                })
    
           }).catch(error => {
                console.log(error.message);
                alert(`${error.message}: please contact support@healthrecoverysolutions.com`)
           })
        }
    };

   let hasFocus = false;
   let hasFocus2 = false;

    return (
      <IonPage>
        <Toolbar />
        <IonContent fullscreen className="ion-padding">
            <IonTitle class='order-title'>RMA Order Form</IonTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <IonCard>
                    <IonCardContent>
                        <IonItem>
                            <IonLabel>Orderer Name * </IonLabel>
                            <IonInput
                                {...register('ordererName', {
                                required: 'Orderer Name is a required field',
                                maxLength: 100
                                })}
                            />
                            {errors.ordererName && errors.ordererName.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="ordererName"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Orderer Email * </IonLabel>
                            <IonInput
                                {...register('ordererEmail', {
                                required: 'Orderer Email is a required field',
                                validate: (value, _) => validDomains.includes(value.split('@')[1].toLowerCase()) || "This is not a valid email. For help, please call 551-203-0011 or email support@healthrecoverysolutions.com",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Invalid email address',
                                },
                                maxLength: 40
                                })}
                            />
                            {errors.ordererEmail && errors.ordererEmail.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="ordererEmail"
                        as={<div style={{ color: 'red' }} />}
                        />
                        <IonItem lines='none'>
                            <IonNote>
                                Note: This email address will receive shipping updates
                            </IonNote>
                        </IonItem>

                        <IonItem>
                            <IonLabel>Orderer Phone Number * </IonLabel>
                            <IonInput
                                {...register('ordererPhoneNumber', {
                                required: 'Orderer Phone Number is a required field',
                                pattern: {
                                    value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                                    message: 'Invalid phone number'
                                }
                                })}
                            />
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="ordererPhoneNumber"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Orderer Organization * </IonLabel>
                            <IonInput
                                {...register('ordererOrganization', {
                                    required: 'Orderer Organization is a required field',
                                    validate: (value, _) => validEnvs.includes(value) || "This is not a valid name. For help, please call 551-203-0011 or email support@healthrecoverysolutions.com"
                                })}
                            />
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="ordererOrganization"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Inventory Subset </IonLabel>
                            <IonInput
                                {...register('inventorySubset')}
                            />
                        </IonItem>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardContent>
                        <IonItem>     
                            <IonLabel>
                                Support Ticket Number
                            </IonLabel> 
                            <IonInput class='ion-text-left'
                                {...register('supportTicketNumber')}
                            />
                        </IonItem>
                        <IonItem lines='none'>
                            <IonNote>
                            All RMAs must be approved by HRS Support. To contact HRS support please email us at support@healthrecoverysolutions.com or call us at +1 (551)-203-0011.
                            </IonNote> 
                        </IonItem>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardTitle class="card-title">Issue Details</IonCardTitle>
                    <IonCardContent>
                        <IonItem>
                            <IonLabel>Order Number with issue</IonLabel>
                            <IonInput
                                {...register('orderNumberWithIssue', {
                                required: 'Order Number is required',
                                minLength: { value: 12, message: "Input must be exactly 12 characters long" },
                                maxLength: { value: 12, message: "Input must be exactly 12 characters long" },
                            })}
                            />
                        </IonItem>
                        <ErrorMessage
                            errors={errors}
                            name="orderNumberWithIssue"
                            as={<div style={{ color: 'red' }} />}
                        />
                        <IonItem lines='none'>
                            <IonNote>
                            Order number can be found in your inventory tracking report or on the bottom left of the shipping label on your kit box
                            </IonNote>
                        </IonItem>


                        <IonItem>      
                            <IonLabel>
                                HRS Kit Number with issue
                            </IonLabel> 
                            <IonInput
                                {...register('inventoryNumber', {
                                required: 'HRS Kit Number is a required field',
                                pattern: {
                                    value: /^[0-9]{5}$|^[0-9]{6}$/i,
                                    message: 'HRS Kit Number must be 5 or 6 digits'
                                }
                                })}
                            />
                        </IonItem>
                        <IonItem lines='none'>
                            <IonNote>
                                5- or 6-digit kit number usually located on the back of your devices
                            </IonNote>
                        </IonItem>
                        <ErrorMessage
                            errors={errors}
                            name="inventoryNumber"
                            as={<div style={{ color: 'red' }} />}
                        />
                        <IonItem>
                            <IonLabel>Select the item(s) that have an issue</IonLabel>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <IonSelect
                                        placeholder="Select item(s)"
                                        value={value}
                                        key={value}
                                        onFocus={() => (hasFocus2 = !hasFocus2)}
                                        onIonChange={(e) => hasFocus2 && onChange(e.detail.value)}
                                        multiple={true}
                                    >
                                    <IonSelectOption value="Tablet">Tablet</IonSelectOption>
                                    <IonSelectOption value="Tablet charging cord/block">Tablet charging cord/block</IonSelectOption>
                                    <IonSelectOption value="Pulse Oximeter">Pulse Oximeter</IonSelectOption>
                                    <IonSelectOption value="Scale">Scale</IonSelectOption>
                                    <IonSelectOption value="Blood Pressure Monitor/Cuff">Blood Pressure Monitor/Cuff</IonSelectOption>
                                    <IonSelectOption value="Glucometer">Glucometer</IonSelectOption>
                                    <IonSelectOption value="Thermometer">Thermometer</IonSelectOption>
                                    <IonSelectOption value="Other">Other</IonSelectOption>
                                    </IonSelect>
                                )}
                                control={control}
                                name="peripheralWithIssue"
                                rules={{ required: 'Item(s) with issue is a required field' }}
                                />
                        </IonItem>
                        <ErrorMessage
                            errors={errors}
                            name="peripheralWithIssue"
                            as={<div style={{ color: 'red' }} />}
                        />
                        <IonItem>      
                            <IonLabel>
                                Issue
                            </IonLabel> 
                            <Controller
                                render={({ field }) => (
                                <IonSelect
                                    placeholder="Select reason"
                                    value={field.value}
                                    onIonChange={e => {
                                        setValue('issueType', e.detail.value);
                                
                                        // Check if the selected value is "Pest Infestation"
                                        if (e.detail.value === 'Pest Infestation') {
                                          setValue('returnAllowed', false);
                                          setIsReturnAllowed(false);
                                          setValue('sameAddress', true);
                                          setIsSameAddress(true);
                                        } else {
                                          // For other options, set the value to true or handle as needed
                                          setValue('returnAllowed', true);
                                          setIsReturnAllowed(true);
                                          setValue('sameAddress', false);
                                          setIsSameAddress(false);
                                        }
                                      }}
                                    interface="action-sheet"
                                >
                                    <IonSelectOption value="Not turning on">Not turning on</IonSelectOption>
                                    <IonSelectOption value="Not pairing">Not pairing</IonSelectOption>
                                    <IonSelectOption value="Not transmitting">Not transmitting</IonSelectOption>
                                    <IonSelectOption value="Error message">Error message</IonSelectOption>
                                    <IonSelectOption value="Inaccurate reading">Inaccurate reading</IonSelectOption>
                                    <IonSelectOption value="Other malfunction">Other malfunction</IonSelectOption>
                                    <IonSelectOption value="Damaged in use">Damaged in use</IonSelectOption>
                                    <IonSelectOption value="Damaged at arrival">Damaged at arrival</IonSelectOption>
                                    <IonSelectOption value="Pest Infestation">Pest Infestation</IonSelectOption>
                                    <IonSelectOption value="Ordered incorrect kit type">Ordered incorrect kit type</IonSelectOption>
                                    <IonSelectOption value="Incorrect/missing material(s) received">Incorrect/missing material(s) received</IonSelectOption>
                                    <IonSelectOption value="Other">Other</IonSelectOption>

                                </IonSelect>
                                )}
                                control={control}
                                name="issueType"
                                rules={{ required: 'Issue is a required field' }}
                            />
                        </IonItem>
                        <ErrorMessage
                            errors={errors}
                            name="issueType"
                            as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel text-wrap>
                                    Do you need a replacement kit sent?
                                </IonLabel> 
                                <Controller
                                    name="needReplacement"
                                    control={control}
                                    render={({ field }) => {
                                    return (
                                        <IonCheckbox
                                        checked={doesNeedReplacement}
                                        onIonChange={handleNeedReplacement}
                                        />
                                    );
                                    }}
                                />
                        </IonItem>
                    </IonCardContent>
                </IonCard>
               
                {doesNeedReplacement && (
                    <IonCard>
                    <IonCardContent>
                        <IonCardTitle class="card-title">Replacement Kit - Ship To Address</IonCardTitle>
                        <IonItem>
                            <IonLabel>Replacement Kit Contents</IonLabel>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <IonSelect
                                        placeholder="Select Kit Contents"
                                        value={value}
                                        key={value}
                                        onFocus={() => (hasFocus = !hasFocus)}
                                        onIonChange={(e) => hasFocus && onChange(e.detail.value)}
                                        multiple={true}
                                    >
                                        <IonSelectOption value="Tablet">Tablet</IonSelectOption>
                                        <IonSelectOption value="Standard Scale">Standard Scale</IonSelectOption>
                                        <IonSelectOption value="Bariatric Scale">Bariatric Scale</IonSelectOption>
                                        <IonSelectOption value="BP Monitor w/ SM Cuff">BP Monitor w/ Small Cuff</IonSelectOption>
                                        <IonSelectOption value="BP Monitor w/ MD Cuff">BP Monitor w/ Medium Cuff</IonSelectOption>
                                        <IonSelectOption value="BP Monitor w/ LG Cuff">BP Monitor w/ Large Cuff</IonSelectOption>
                                        <IonSelectOption value="Pulse Oximeter">Pulse Oximeter</IonSelectOption>
                                        <IonSelectOption value="Thermometer">Thermometer</IonSelectOption>
                                        <IonSelectOption value="Glucometer">Glucometer</IonSelectOption>

                                    </IonSelect>
                                )}
                                control={control}
                                name="replacementKit"
                                rules={{ required: 'Replacement Kit Contents is a required field' }}
                                />
                        </IonItem>
                        <IonItem lines='none'>
                            <IonNote>
                                <IonText>Note:</IonText>
                                <ul>
                                    <li>Must order a kit type that your organization is contracted for.</li>
                                    <li>Tablet comes with 2 charging cords and 2 charging blocks</li>
                                    <li>Glucometer comes with 1 box of 50 test strips, 1 box of 100 lancets, 1 lancing device, control solution</li>
                                    <li>Thermometer comes with ear probe covers</li>
                                    <li>All kits that have peripheral devices included also come with extra batteries</li>
                                </ul>  
                            </IonNote>
                        </IonItem>
                        <ErrorMessage
                                errors={errors}
                                name="replacementKit"
                                as={<div style={{ color: 'red' }} />}
                            />

                        <IonItem>
                            <IonLabel>ClinicianConnect Patient ID</IonLabel>
                            <IonInput
                                {...register('patientId')}
                            />
                        </IonItem>
                        <IonItem>
                                <IonLabel>Should this patient receive the Remote Patient Onboarding service?</IonLabel>
                                <Controller
                                    name="virtualInstall"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <IonCheckbox
                                                checked={field.value}
                                                onIonChange={e => {
                                                    setValue('virtualInstall', e.detail.checked);
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </IonItem>
                            <IonItem lines='none'>
                                <IonNote>Note: Service will only be provided if your organization is signed up for the Remote Patient Onboarding service. This service includes delivery status tracking and patient equipment education and installation which is initiated via phone call and/or text. Please contact your account representative if you’re interested in adding this service.</IonNote>
                            </IonItem>


                        <IonItem>
                            <IonLabel>Full Name * </IonLabel>
                            <IonInput
                                {...register('patientName', {
                                required: 'Full Name is a required field',
                                maxLength: 100
                                })}
                            />
                            {errors.patientName && errors.patientName.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="patientName"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Phone Number *</IonLabel>
                            <IonInput
                                {...register('patientPhoneNumber', {
                                required: 'Phone Number is a required field',
                                pattern: {
                                    value: /^(\(\+[0-9]{2}\))?([0-9]{3}-?)?([0-9]{3})\-?([0-9]{4})(\/[0-9]{4})?$/i,
                                    message: 'Invalid phone number'
                                }
                                })}
                            />
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="patientPhoneNumber"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Address 1 * </IonLabel>
                            <IonInput
                                {...register('patientAddress1', {
                                required: 'Address 1 is a required field',
                                maxLength: 30
                                })}
                            />
                            {errors.patientAddress1 && errors.patientAddress1.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="patientAddress1"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Address 2 </IonLabel>
                            <IonInput
                                {...register('patientAddress2', {
                                    maxLength: 30
                                })}
                            />
                            {errors.patientAddress2 && errors.patientAddress2.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                        </IonItem>

                        <IonItem>
                            <IonLabel>City * </IonLabel>
                            <IonInput
                                {...register('patientCity', {
                                required: 'City is a required field',
                                maxLength: 25
                                })}
                            />
                            {errors.patientCity && errors.patientCity.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="patientCity"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>State * </IonLabel>
                            <IonInput
                                {...register('patientState', {
                                    required: 'Patient State is a required field',
                                    pattern: {
                                        value: /^[A-Z]{2}$/i,
                                        message: 'Invalid State code, please use two capital letters'
                                    },
                                    validate: (value, _) =>  validStates.includes(value.toUpperCase()) || "Invalid State",
                                })}
                            /> 
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="patientState"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Postal Code * </IonLabel>
                            <IonInput
                                {...register('patientPostalCode', {
                                    required: 'Postal Code is a required field',
                                    pattern: {
                                        value: /^[0-9]{5}(?:-[0-9]{4})?$/i,
                                        message: 'Invalid postal code'
                                    }
                                })}
                            />
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="patientPostalCode"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Shipping Speed *</IonLabel>
                            <Controller
                                render={({ field }) => (
                                    <IonSelect
                                        placeholder="Select Shipping Speed"
                                        value={field.value}
                                        onIonChange={e => setValue('shippingSpeedSelect', e.detail.value)}
                                        interface="action-sheet"
                                    >
                                        <IonSelectOption value="Ground">Ground</IonSelectOption>
                                        <IonSelectOption value="2nd Day Air">2nd Day</IonSelectOption>
                                        <IonSelectOption value="Next Day Air Saver">Next Day</IonSelectOption>


                                    </IonSelect>
                                )}
                                control={control}
                                name="shippingSpeedSelect"
                                rules={{ required: 'Shipping Speed is a required field' }}
                            />
                        </IonItem>
                        <ErrorMessage
                            errors={errors}
                            name="shippingSpeedSelect"
                            as={<div style={{ color: 'red' }} />}
                        />


                    </IonCardContent>
                </IonCard>

                )}
                
                
                {isReturnAllowed && (
                    <IonCard>
                    <IonCardContent>
                        <IonCardTitle class="card-title">Return of Kit with Issue</IonCardTitle>
                        <IonItem>
                            <ul>
                                <li>
                                    <strong>Must return the entire kit.</strong>
                                </li>
                                <li>
                                Please notify the patient that the carrier will come pickup the damaged kit on the following business day (for requests submitted by 1PM ET).
                                </li>
                                <li>
                                If pickup is unsuccessful, the carrier will make two additional pickup attempts on subsequent business days.
                                </li>
                            </ul> 
                        </IonItem>

                        {doesNeedReplacement && (
                            <IonItem>      
                                <IonLabel text-wrap>
                                    Is the pickup address the same as the shipping address above?
                                </IonLabel> 
                                <Controller
                                    name="sameAddress"
                                    control={control}
                                    render={({ field }) => {
                                    return (
                                        <IonCheckbox
                                        checked={isSameAddress}
                                        onIonChange={handleIsSameAddressChange}
                                        />
                                    );
                                    }}
                                />
                            </IonItem>  
                        )}
                        
                    </IonCardContent>
                </IonCard>
                )}
                

                {isReturnAllowed && (
                    (!isSameAddress) && (
                        <IonCard>
                        <IonCardContent>
                            <IonCardTitle class="card-title">Pickup Address for Return Shipment</IonCardTitle>
                            <IonItem>
                                <IonLabel>Full Name *</IonLabel>
                                <IonInput
                                    {...register('pickupPatientName', {
                                    required: 'Full Name is a required field',
                                    maxLength: 100
                                    })}
                                />
                                {errors.pickupPatientName && errors.pickupPatientName.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                            <ErrorMessage
                            errors={errors}
                            name="pickupPatientName"
                            as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Phone Number *</IonLabel>
                                <IonInput
                                    {...register('pickupPhoneNumber', {
                                    required: 'Phone Number is a required field',
                                    pattern: {
                                        value: /^(\(\+[0-9]{2}\))?([0-9]{3}-?)?([0-9]{3})\-?([0-9]{4})(\/[0-9]{4})?$/i,
                                        message: 'Invalid phone number'
                                    }
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                            errors={errors}
                            name="pickupPhoneNumber"
                            as={<div style={{ color: 'red' }} />}
                            />
                            
                            <IonItem>
                                <IonLabel>Address 1 *</IonLabel>
                                <IonInput
                                    {...register('pickupAddress1', {
                                    required: 'Address 1 is a required field',
                                    maxLength: 100
                                    })}
                                />
                                {errors.pickupAddress1 && errors.pickupAddress1.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                            <ErrorMessage
                            errors={errors}
                            name="pickupAddress1"
                            as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Address 2 </IonLabel>
                                <IonInput
                                    {...register('pickupAddress2', {
                                        maxLength: 30
                                    })}
                                />
                                {errors.pickupAddress2 && errors.pickupAddress2.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>

                            <IonItem>
                                <IonLabel>City * </IonLabel>
                                <IonInput
                                    {...register('pickupCity', {
                                    required: 'City is a required field',
                                    maxLength: 25
                                    })}
                                />
                                {errors.pickupCity && errors.pickupCity.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                            <ErrorMessage
                            errors={errors}
                            name="pickupCity"
                            as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>State * </IonLabel>
                                <IonInput
                                    {...register('pickupState', {
                                        required: 'State is a required field',
                                        pattern: {
                                            value: /^[A-Z]{2}$/i,
                                            message: 'Invalid State code, please use two capital letters',
                                        },
                                        validate: (value, _) => validStates.includes(value.toUpperCase()) || "Invalid State"
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="pickupState"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Postal Code *</IonLabel>
                                <IonInput
                                    {...register('pickupPostalCode', {
                                        required: 'Postal Code is a required field',
                                        pattern: {
                                            value: /^[0-9]{5}(?:-[0-9]{4})?$/i,
                                            message: 'Invalid postal code'
                                        }
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="pickupPostalCode"
                                as={<div style={{ color: 'red' }} />}
                            />
                        </IonCardContent>
                    </IonCard>
                    )
                )}

                <IonCard>
                    <IonCardContent>
                        <IonCardTitle class="card-title">
                            Additional Information
                        </IonCardTitle>
                        <IonItem>
                            <IonLabel text-wrap>Notes for Warehouse Team</IonLabel>
                            <IonInput
                                {...register('warehouseComments', {
                                    maxLength: 35
                                })}
                            />
                            {errors.warehouseComments && errors.warehouseComments.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }

                        </IonItem>
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardContent>
                        {Object.keys(errors).length > 0 && (
                                <div style={{ color: 'red' }}>Invalid entries, please check information entered and try again</div>
                        )}
                        <IonButton class="order-form-btn" expand="block" type="submit">
                            Submit Order
                        </IonButton>
                        <IonItem lines='none'>
                                <IonNote>
                                    <ul>
                                        <li>Same-day shipment for orders placed by 1:00 PM ET on business days when kits are in stock.</li>
                                        <li>For return requests submitted by 1:00 PM ET, the first pickup attempt will be on the following business day.</li>
                                        <li>Returns shipped Ground.</li>
                                        <li>Signature is required on all deliveries.</li>
                                    </ul>
                                </IonNote>
                            </IonItem>
                    </IonCardContent>
                </IonCard>


            </form>
        </IonContent>
      </IonPage>
    );
  };
  
  export default StandardRMA;