import { IonButton, IonContent, IonFooter, IonGrid, IonPage, IonRow, IonTitle, IonToolbar} from '@ionic/react';
import ShippingTimelineCard from '../components/ShippingTimelineCard';
import VirtualInstallInfoCard from '../components/VirtualInstallInfoCard';
import Toolbar from '../components/ToolBar';
import React  from 'react';
import './Home.css';

const Home: React.FC = () => {
  return (
    <IonPage>
      <Toolbar />
      <IonContent fullscreen>    
        <ShippingTimelineCard />
        <VirtualInstallInfoCard />
        <IonRow>
            <IonButton id='begin-button' routerLink="/order/single">Place Order</IonButton>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Home;
