import './InfoCard.css';
import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonList, IonNote, IonText } from '@ionic/react';


function ShippingTimelineCard() {
    return (
      <IonCard>
        <IonCardHeader>
          <IonCardTitle class='card-title'>Welcome to HRS Logistics!</IonCardTitle>
        </IonCardHeader>
  
        <IonCardContent>
        <ul className="card-list">
                <li>
                Same-day shipment for orders placed by <strong>1:00 PM ET</strong> on business days when kits are in stock.
                </li>
                <li>
                For return requests submitted by 1:00 PM ET, the first pickup attempt will be on the following business day.
                </li>
                <li>
                All orders shipped Ground speed.
                </li>
                <li>
                  Signature is required on all deliveries.
                </li>

            </ul>
            <IonText>
            Contact&nbsp;<a href="mailto:support@healthrecoverysolutions.com">support@healthrecoverysolutions.com</a>&nbsp;with any inquiries.
            </IonText>
       
            
        </IonCardContent>
      </IonCard>
    );
  }
  export default ShippingTimelineCard;