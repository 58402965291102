/* eslint-disable */
import './InfoCard.css'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import React  from 'react';

function VirtualInstallInfoCard() {
    return (
      <IonCard>
        <IonCardHeader>
          <IonCardTitle class='card-title'>If the patient or caregiver should receive the Remote Patient Onboarding service, they must meet the following criteria:</IonCardTitle>
        </IonCardHeader>
  
        <IonCardContent>
            <ul className="card-list">
                <li>
                Consent to the Remote Patient Onboarding over the phone.
                </li>
                <li>
                Be physically able to carry, open, and unpack the box.
                </li>
                <li>
                Have the dexterity to pair the equipment to a smart device.
                </li>
                <li>
                Demonstrate understanding of specified program goals (measuring biometrics, answering survey questions, accessing education, completing video calls) and reaching both HRS Tech Support and your organization's specified Care Team Support.
                </li>
            </ul>
        </IonCardContent>
      </IonCard>
    );
  }
  export default VirtualInstallInfoCard;